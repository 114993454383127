import React from 'react'
import ShippingOptions, { PreviouslySelectedShippingOption } from '../components/ShippingOptions'
import { ButtonRed } from 'styles/buttons'
import { AreaContentRow, AreaHeading, Area, HorizontalPack } from '../styledComponents'
import { useCheckout2 } from 'setup/CheckoutProviderFields'
import Loader from 'pageComponents/_common/loader'
import Required from 'pageComponents/_common/required'
import { StatusIcon } from './Checkout2Summary'

export default function Shipping() {
	const {
		checkoutInitializeData,
		checkoutEditableFields,
		shippingOptions,
		shippingDataIsLoading,
		shippingNoRatesAvailable,
		getShippingData,
		validationStatus,
		inPageRefs
	} = useCheckout2();

	const shippingGroups = [];

	return (
		<Area>
			<AreaHeading ref={inPageRefs.shippingChoicesSectionRef}>
				<h1>Shipping Choices</h1>
				<StatusIcon isSectionValid={validationStatus.IsShippingOptionValid} validText='A shipping option is selected' invalidText='A shipping option is not selected' sectionTitle='Shipping Option is Selected' />
			</AreaHeading>
			<AreaContentRow>
				<HorizontalPack>
					{shippingDataIsLoading && <Loader />}
					{!shippingDataIsLoading && <ButtonRed id={`GetShippingRatesBtn`} onClick={getShippingData} disabled={!validationStatus.IsShipToValid}>Get Shipping Rates</ButtonRed>}
					{!validationStatus.IsShipToValid && <span style={{ fontStyle: 'italic', alignSelf: 'center' }}>Provide a valid ship to address to continue</span>}
				</HorizontalPack>
			</AreaContentRow>
			{shippingGroups && shippingGroups.length > 0 &&
				<AreaContentRow>
					<span>Your order will be fufilled in {shippingGroups.length} shipments using the selected shipping option</span>
				</AreaContentRow>
			}
			{checkoutInitializeData && shippingNoRatesAvailable && (
				<span><Required /> We were unable to get rates for this order at this time. This could be due to an LTL item, one of the shipments being overweight, or a problem with our shipping providers. You may continue to check out, and our warehouse will determine the best shipping method.</span>
			)}
			{checkoutInitializeData && !shippingNoRatesAvailable && shippingOptions?.length > 0 && (
				<ShippingOptions />
			)}
			{checkoutInitializeData && !shippingNoRatesAvailable && (shippingOptions?.length === 0) && validationStatus.IsShippingOptionValid && (
				<PreviouslySelectedShippingOption carrierName={checkoutEditableFields.ShippingChoices_SelectedShippingCarrier} price={validationStatus.ShippingTotal || ''} />
			)}

			{shippingOptions?.length > 0 && <AreaContentRow><span>Note: Ship and Delivery dates are good faith estimates, assuming all items are in-stock at our warehouse or arrive when expected based on lead time. Estimates can change due to a variety of factors, including backorder and factory-shipped items.</span></AreaContentRow>}
		</Area>
	)
}
