import React, { useContext, useState, useEffect, useRef, lazy, Suspense } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import TopAlert from './headerAlertModal'
import Context from '../../setup/context'
import Loader from '../../pageComponents/_common/loader'
import { NavigationItemContainer, DropdownMenu, DropdownMenuItem, MyAccountDropdownMenu, ImgDropdownMenu, ShopImgDropdownMenu, BrandDropdownMenu } from 'pageComponents/_common/dropdown-menu/DropdownMenu'
import { buildSearchString, onWindowResize } from '../../pageComponents/_common/helpers/generalHelperFunctions'
import { useQuery } from '@apollo/client'
import { GET_ROOT_CATEGORIES_HEADER } from 'setup/providerGQL'
import { Button, Menu } from '@mui/material'
import queryString from 'query-string'
import HidePrint from '../../pageComponents/_common/HidePrint'
import OrderDetailModal from '../modals/orderDetailMenu'
import MenuVisibility from 'pageComponents/_common/dropdown-menu/MenuVisibility'
import { useAuth } from 'react-oidc-context'

const AdvancedSearch = lazy(() => import('../modals/AdvancedSearch'))
const ImpersonationSearch = lazy(() => import('./impersonationSearch'))
const NotesModal = lazy(() => import('../modals/Notes'))
const BrandsDropdownMenu = lazy(() => import('pageComponents/_common/dropdown-menu/BrandsDropdownMenu'))
const ShopDropdownMenu = lazy(() => import('pageComponents/_common/dropdown-menu/ShopDropdownMenu'))
const AboutDropdownMenu = lazy(() => import('pageComponents/_common/dropdown-menu/AboutDropdownMenu'))
const ContactDropdownMenu = lazy(() => import('pageComponents/_common/dropdown-menu/ContactDropdownMenu'))
const ResourcesDropdownMenu = lazy(() => import('pageComponents/_common/dropdown-menu/ResourcesDropdownMenu'))

const Nav = styled.div`
	position: ${props => props.location.pathname === '/search' && window.innerWidth < 750 ? 'relative' : '-webkit-sticky'};
	position: ${props => props.location.pathname === '/search' && window.innerWidth < 750 ? 'relative' : (props.disableSticky ? 'inherit' : 'sticky')};
	top: 0;
    align-self: flex-start;
    z-index: 5;
    width: 100%;
`
const NavTop = styled.div`
	display: flex;
	margin: 0 auto;
	padding: 2px 0;
	width: 100%;
	background-color: #535353;
	justify-content: center;
`
const NavBottom = styled.div`
	margin: 0 auto;
	width: 100%;
	background-color: white;
	box-shadow: 0px 3px 4px #dadada;
`
const NavContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	height: 100%;
	align-items: center;
`
const ReverseNavContainer = styled.div`
	display: flex;
	flex-direction: row-reverse;
	flex-wrap: wrap;
	height: 100%;
	width: 100%;
	align-items: center;
`
const LinkContainer = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	color: black;
	font-size: 14px;
	font-weight: 500;
`
const InputSearch = styled.input`
	width: 260px;
	height: 40px;
	font-size: 15px;
	border-color: #dadada;
	border-top: 1px #dadada solid;
	border-left: 1px #dadada solid;
	border-bottom: 1px #e7e7e7 solid;
	border-right: 0px;
	padding: 0 8px;
	&:focus{
		border-top: 1px #b4b4b4 solid;
		border-left: 1px #b4b4b4 solid;
		border-bottom: 1px #b4b4b4 solid;
	}
	@media (max-width: 365px) {
		width: 215px;
	}
`
const ButtonSearch = styled.button`
	width: 50px;
	height: 40px;
	background-image: linear-gradient(to top left, ${props => props.theme.mainColorBlend},  ${props => props.theme.mainColor});
	color: ${props => props.theme.buttonForegroundColor};
	font-weight: 500;
	border: 0;
	font-size: 14px;
	border-radius: 0 5px 5px 0;
`
const ButtonSearchType = styled.button`
	width: 40px;
	height: 40px;
	background-image: linear-gradient(to top left, #404040, #272727);
	border-radius: 3px 0px 0 3px;
	color: white;
	font-weight: 500;
	border: 0;
	font-size: 14px;
`
const Row = styled.div`
	display: flex;
	align-items: center;
`
const UserNameRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 0 5px;
`
const Puser = styled.p`
	background-image: linear-gradient(to top left, #404040, #333);
	color: #f3f3f3;
	font-size: 11px;
	margin: 0;
	padding: 4px 8px;
	border-radius: 30px;
	font-weight: 600;
`
const PeUser = styled(Puser)`
	background-image: linear-gradient(to top left, #328efc, #133752);
	color: #f3f3f3;
	font-weight: 600;
`
const P = styled.p`
	cursor: pointer;
	color: #f3f3f3;
	margin: 0;
	font-size: 14px;
	font-weight: 600;
	padding: 0 5px;
	white-space: nowrap;
`
const A = styled.a`
	cursor: pointer;
	color: #f3f3f3;
	margin: 0;
	font-size: 14px;
	font-weight: 600;
	padding: 0 5px;
	white-space: nowrap;
	&:hover {
		color: #f3f3f3;
	}
`
const Aphone = styled(P)`
  margin: 0;
	color: white;
	white-space: nowrap;
`
const DivCancelImpersonation = styled.div`
	cursor: pointer;
	margin: 0 8px;
`
const GrayDiv = styled.div`
	color: gray;
`
const GreenDiv = styled.div`
	color: limegreen;
`
const SearchBarRow = styled.div`
 	display: flex;
 	flex: 1;
 	justify-content: flex-end;
 	@media (max-width: 755px) {
 		justify-content: center;
	}
`
const AccountSectionRow = styled.div`
	display: flex;
	flex-wrap: wrap;
 	flex: 1;
 	justify-content: flex-end;
 	padding: 5px 0;
`

const LoaderContainer = styled.div`
    height: 20px;
    width: 58px;
    position: relative;
    top: -20px;
`
const AirlineLogoImg = styled.img`
    margin-left: 5px;
`

export default function HeaderComponent(props) {
	const tabContainerRef = useRef(null);
	const tabRefs = useRef([]);
	const location = useLocation();
	const navigate = useNavigate();
	const [categories, setCategories] = useState([]);
	const tabDeclaration = headerTabs(categories);
	const [visibleTabCount, setVisibleTabCount] = useState(tabDeclaration.length);
	const [overflowMenu, setOverflowMenu] = useState(null);
	const [showAdvancedModal, setShowAdvancedModal] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchAsCustomer, setSearchAsCustomer] = useState(false);
	const [showMyAccountDropdown, setShowMyAccountDropdown] = useState(false);
	const context = useContext(Context);
	const [isOrderLookupOpen, setIsOrderLookupOpen] = useState(false);
	const [OpenDropDownMenu, setOpenDropDownMenu] = useState({});
	const auth = useAuth();

	useQuery(GET_ROOT_CATEGORIES_HEADER, {
		onCompleted: data => {
			setCategories(data.getAllRootCategories);
		}
	});

	const calculateTabs = () => {
		const containerRight = tabContainerRef.current && tabContainerRef.current.getBoundingClientRect().right
		const widthOfTheComponentsToTheRightOfTheTabs = containerRight < 724 ? 70 : 420 //SearchBar wraps at < 724
		const count = tabRefs.current.reduce((count, tabRight) => {
			if (tabRight <= (containerRight - widthOfTheComponentsToTheRightOfTheTabs)) {
				count += 1
			}
			return count
		}, 0);
		setVisibleTabCount(count);
	};

	useEffect(() => {
		calculateTabs();
		return onWindowResize(calculateTabs);
	}, [tabRefs.current]);

	const setTabRef = idx => ref => {
		const right = ref && ref.getBoundingClientRect().right;
		if (right) tabRefs.current[idx] = right;
	};

	const toMenu = ({ image, label, to, subItems, isExternalLink }, idx) => {
		if (window.innerWidth > 1200) {
			if (label === 'Brands') {
				return (
					<MenuVisibility {...{ setOpenDropDownMenu, name: "brands" }} key={label}>
						<NavigationItemContainer to={to} isExternalLink={isExternalLink} text={label} key={label} image={image} ref={setTabRef(idx)}>
							<BrandDropdownMenu>
								{OpenDropDownMenu.brands ?
									(<Suspense fallback={<Loader />}>
										<BrandsDropdownMenu />
									</Suspense>) : null
								}
							</BrandDropdownMenu>
						</NavigationItemContainer>
					</MenuVisibility>
				)
			}
			if (label === 'Shop') {
				return (
					<MenuVisibility {...{ setOpenDropDownMenu, name: "Shop" }} key={label}>
						<NavigationItemContainer to={to} isExternalLink={isExternalLink} text={label} key={label} image={image} ref={setTabRef(idx)}>
							<ShopImgDropdownMenu>
								{OpenDropDownMenu.Shop ?
									(<Suspense fallback={<Loader />}>
										<ShopDropdownMenu />
									</Suspense>) : null
								}
							</ShopImgDropdownMenu>
						</NavigationItemContainer>
					</MenuVisibility>
				)
			}
			if (label === 'About') {
				return (
					<MenuVisibility {...{ setOpenDropDownMenu, name: "About" }} key={label}>
						<NavigationItemContainer to={to} isExternalLink={isExternalLink} text={label} key={label} image={image} ref={setTabRef(idx)}>
							<ImgDropdownMenu>
								{OpenDropDownMenu.About ?
									(<Suspense fallback={<Loader />}>
										<AboutDropdownMenu />
									</Suspense>) : null
								}
							</ImgDropdownMenu>
						</NavigationItemContainer>
					</MenuVisibility>
				)
			}
			if (label === 'Contact') {
				return (
					<MenuVisibility {...{ setOpenDropDownMenu, name: "Contact" }} key={label}>
						<NavigationItemContainer to={to} isExternalLink={isExternalLink} text={label} key={label} image={image} ref={setTabRef(idx)}>
							<ImgDropdownMenu>
								{OpenDropDownMenu.Contact ?
									(<Suspense fallback={<Loader />}>
										<ContactDropdownMenu />
									</Suspense>) : null
								}
							</ImgDropdownMenu>
						</NavigationItemContainer>
					</MenuVisibility>
				)
			}
			if (label === 'Resources') {
				return (
					<MenuVisibility {...{ setOpenDropDownMenu, name: "Resources" }} key={label}>
						<NavigationItemContainer to={to} isExternalLink={isExternalLink} text={label} key={label} image={image} ref={setTabRef(idx)}>
							<ImgDropdownMenu>
								{OpenDropDownMenu.Resources ?
									(<Suspense fallback={<Loader />}>
										<ResourcesDropdownMenu />
									</Suspense>) : null
								}
							</ImgDropdownMenu>
						</NavigationItemContainer>
					</MenuVisibility>
				)
			}
		}

		return (

			<NavigationItemContainer to={to} isExternalLink={isExternalLink} text={label} key={label} image={image} ref={setTabRef(idx)}>
				<DropdownMenu>
					{subItems && subItems.map(toMenuItem)}
				</DropdownMenu>
			</NavigationItemContainer>
		)
	}

	const toMenuItem = ({ label, to, isExternalLink }) => <DropdownMenuItem key={label} to={to} isExternalLink={isExternalLink}>{label}</DropdownMenuItem>

	const tabComponents = tabDeclaration.map(toMenu);

	const handleSearch = () => {
		const parsedQueryString = queryString.parse(location.search);
		const search = searchTerm?.length ? searchTerm : parsedQueryString.searchTerm;
		const hasNonWebChanged = searchAsCustomer !== !!parsedQueryString.nonweb;
		if (search?.length || hasNonWebChanged) navigate(buildSearchString({ searchTerm: search, nonweb: searchAsCustomer }));
	};

	const handleKeyPress = e => e.key === 'Enter' && handleSearch();

	const searchPlaceholder = searchAsCustomer ? '[Non-web Included] Search by Part # or Keyword' : 'Search by Part # or Keyword';

	const MyAccountDropdown = () => (
		<div
			id="myAccount"
			onMouseEnter={() => setShowMyAccountDropdown(true)}
			onMouseLeave={() => setShowMyAccountDropdown(false)}
		>
			<Link to="/account/dashboard" style={{ textDecoration: 'none' }}>
				<P id="myAccount">My Account</P>
			</Link>

			<MyAccountDropdownMenu className={showMyAccountDropdown ? 'visible' : ''}>
				<DropdownMenuItem to="/account/shopping-lists">Shopping Lists</DropdownMenuItem>
				<DropdownMenuItem to="/pages/contact/contact-us">Request for Quote</DropdownMenuItem>
				<DropdownMenuItem to="/account/dashboard">Account Profile</DropdownMenuItem>
				<DropdownMenuItem to="/account/invoices">Invoices</DropdownMenuItem>
				<DropdownMenuItem to="/account/orders">Orders</DropdownMenuItem>
				<DropdownMenuItem to="/account/open-orders-report">Open Orders Report</DropdownMenuItem>
				<DropdownMenuItem to="/account/quotes">Open Quotes</DropdownMenuItem>
				<DropdownMenuItem to="/account/my-ordered-items">Item Purchase History</DropdownMenuItem>
			</MyAccountDropdownMenu>
		</div>
	);

	const AccountSection = () => (
		<AccountSectionRow>
			<Row style={{ justifyContent: 'center' }}>
				<FontAwesomeIcon icon="phone-alt" color="white" />
				<Aphone href="tel:+18009997378">800-999-7378</Aphone>
			</Row>

			<Row style={{ justifyContent: 'center' }}>
				{auth.isAuthenticated
					? <P onClick={() => void auth.signoutRedirect() /*Note, sign out isn't complete until the user is redirected back to post_logout_url, in oidc-settings.js */}>Sign Out</P>
					: <P onClick={() => void auth.signinRedirect()}>Sign In</P>
				}

				<P>|</P>

				{auth.isAuthenticated
					? <P onClick={() => navigate('/account/orders')}>My Orders</P>
					: window.innerWidth > 1024 ?
						<P onClick={() => setIsOrderLookupOpen(true)}>Order Lookup</P> :
						<P onClick={() => { navigate("/mobile/orderlookup") }}>Order Lookup</P>
				}

				<OrderDetailModal onClick={() => setIsOrderLookupOpen(true)} open={isOrderLookupOpen} hide={setIsOrderLookupOpen}></OrderDetailModal>

				<P>|</P>

				{auth.isAuthenticated
					? <MyAccountDropdown />
					: <A href="/signup">Request Account</A>
				}

				<P>|</P>

				{context.cart && !context.cartLoading && (
					<Link to='/cart' style={{ textDecoration: 'none' }}>
						{/* <Link to='/cart' style={{ textDecoration: 'none' }}> */}
						<P>Cart({context.cart.length})</P>
					</Link>
				)}
				{(!context.cart || context.cartLoading) && (
					<LoaderContainer>
						<Loader />
					</LoaderContainer>
				)}
			</Row>
		</AccountSectionRow>
	);

	const SearchBar = (
		<SearchBarRow>
			{context.userInfo?.isAirlineEmployee && (
				<ButtonSearchType onClick={() => setSearchAsCustomer(!searchAsCustomer)}>
					{searchAsCustomer ? <GreenDiv>NW</GreenDiv> : <GrayDiv>NW</GrayDiv>}
				</ButtonSearchType>
			)}

			<InputSearch
				value={searchTerm}
				placeholder={searchPlaceholder}
				onChange={e => setSearchTerm(e.target.value)}
				onKeyPress={handleKeyPress}
			/>

			<ButtonSearch onClick={handleSearch} aria-label="Search icon">
				<FontAwesomeIcon icon="search" color="#f6f6f6" size="lg" />
			</ButtonSearch>
		</SearchBarRow>
	);

	return (
		<Nav location={location} {...props}>
			<HidePrint>
				{context.topAlert?.show && <TopAlert message={context.topAlert.message} close={context.removeTopAlert} />}
				<NavTop>
					<ReverseNavContainer>
						<AccountSection />
						<UserNameSection {...context} setShowAdvancedModal={setShowAdvancedModal} />
					</ReverseNavContainer>
				</NavTop>

				<NavBottom>
					<NavContainer ref={tabContainerRef}>
						<Row>
							<Link to="/">
								<AirlineLogoImg src="https://airlinemedia.airlinehyd.com/Airline's_logo/airline_75_years.svg" width="120" alt="AirlineHyd.com" />
							</Link>

							<LinkContainer>
								{tabComponents.slice(0, visibleTabCount)}

								{visibleTabCount < tabDeclaration.length && (
									<Button onClick={e => setOverflowMenu(e.currentTarget)} color="inherit" aria-label="ellipsis-h icon">
										<FontAwesomeIcon icon="ellipsis-h" />
									</Button>
								)}

								<Menu
									disableScrollLock={true}
									onClick={() => setOverflowMenu(null)}
									MenuListProps={{ style: { backgroundColor: '#535353' } }}
									anchorEl={overflowMenu}
									open={!!overflowMenu}
									click
									onClose={() => setOverflowMenu(null)}
								>
									{tabDeclaration
										.slice(visibleTabCount, tabDeclaration.length)
										.map(toMenuItem)
									}
								</Menu>
							</LinkContainer>
						</Row>

						{SearchBar}
					</NavContainer>
				</NavBottom>
				{showAdvancedModal && (
					<Suspense fallback={<Loader />}>
						<AdvancedSearch open={showAdvancedModal} onClose={() => setShowAdvancedModal(false)} />
					</Suspense>
				)}
			</HidePrint>
		</Nav>
	);
}

function UserNameSection({ userInfo, impersonatedCompanyInfo, cancelImpersonation, setShowAdvancedModal }) {
	if (userInfo && !impersonatedCompanyInfo) {
		return (
			<UserNameRow style={{ flex: 1 }}>
				<Puser>
					Hello, {userInfo.preferredName} ({userInfo.companyName} - {userInfo.companyId})
				</Puser>
				{userInfo.isAirlineEngineerUser && (
					<Suspense fallback={<Loader />}>
						<ImpersonationSearch setShowAdvancedModal={setShowAdvancedModal} />
					</Suspense >
				)
				}
			</UserNameRow>
		);
	} else if ((userInfo && impersonatedCompanyInfo)) {
		return (
			<UserNameRow style={{ flex: 1 }}>
				<a target='_' href={`${process.env.REACT_APP_WEB_CONNECT_URL}/common/CustomerDetails.aspx?CustomerID=${impersonatedCompanyInfo.CustomerIdP21}&CompanyID=AIRLINE`}>
					<PeUser>
						<FontAwesomeIcon icon="user-circle" color="#f3f3f3" />
						{impersonatedCompanyInfo.CustomerName} - {impersonatedCompanyInfo.CustomerIdP21} [Impersonating]
					</PeUser>
				</a>
				<DivCancelImpersonation onClick={cancelImpersonation}>
					<FontAwesomeIcon icon="times" color="white" />
				</DivCancelImpersonation>
				<Suspense fallback={<Loader />}>
					<ImpersonationSearch setShowAdvancedModal={setShowAdvancedModal} />
				</Suspense>
				{impersonatedCompanyInfo?.CustomerMandatoryNotes && (
					<Suspense fallback={<Loader />}>
						<NotesModal notes={impersonatedCompanyInfo.CustomerMandatoryNotes} />
					</Suspense>
				)}
			</UserNameRow>
		);
	} else {
		return null;
	}
}

// const servicesSubItems = [
//     {
//         label: 'Engineered Systems & Assemblies',
//         to: '/pages/services/engineered-systems-and-assemblies'
//     },
//     {
//         label: 'Repair │ Field Service │ Maintenance',
//         to: '/pages/services/repair-field-service'
//     },
//     {
//         label: 'Safety',
//         to: '/pages/services/safety'
//     },
//     {
//         label: 'Efficiency',
//         to: '/pages/services/efficiency'
//     },
//     {
//         label: 'Climate Control Services',
//         to: 'https://info.airlinehyd.com/rittal-climate-control-services',
//         isExternalLink: true
//     },
//     {
//         label: 'Networking Services ',
//         to: 'https://info.airlinehyd.com/networking-services-by-phoenix',
//         isExternalLink: true
//     }
// ]

// const industriesSubItems = [
//     {
//         label: 'Physical Distancing Barriers',
//         to: '/pages/industries/commercial-protective-barriers'
//     },
//     {
//         label: 'Primary Metals',
//         to: '/pages/industries/primary-metals'
//     },
//     {
//         label: 'Power Generation',
//         to: '/pages/industries/power-generation'
//     },
//     {
//         label: 'Marine',
//         to: '/pages/industries/marine'
//     },
//     {
//         label: 'Food Beverage & Packaging',
//         to: '/pages/industries/food-beverage-and-packaging'
//     },
//     {
//         label: 'Plastics & Rubber',
//         to: '/pages/industries/plastics-and-rubber'
//     },
//     {
//         label: 'Construction and Off Road',
//         to: '/pages/industries/construction-off-road'
//     },
//     {
//         label: 'Life Sciences',
//         to: '/pages/industries/life-sciences'
//     },
//     {
//         label: 'Industrial Machinery',
//         to: '/pages/industries/industrial-machinery'
//     },
//     {
//         label: 'Chemical & Water Processing',
//         to: '/pages/industries/chemical-and-water-processing'
//     },
//     {
//         label: 'Pulp & Paper',
//         to: '/pages/industries/pulp-and-paper'
//     },
//     {
//         label: 'Mining & Drilling',
//         to: '/pages/industries/mining-and-drilling'
//     }
// ]

const brandsSubItems = [
	{

		label: 'All Brands',
		to: '/pages/brands'
	},
	{
		label: 'ABB',
		to: '/brands/featured/abb'
	},
	{
		label: 'Banner',
		to: '/brands/featured/banner'
	},
	{
		label: 'Butech',
		to: '/brands/featured/butech'
	},
	{
		label: 'Clippard',
		to: '/brands/featured/clippard'
	},
	{
		label: 'Eaton',
		to: '/brands/featured/eaton'
	},
	{
		label: 'Grace Technologies',
		to: '/pages/brands/grace-technologies/'
	},
	{
		label: 'Haskel',
		to: '/brands/featured/haskel'
	},
	{
		label: 'HYDAC',
		to: '/brands/featured/hydac'
	},
	{
		label: 'icotek',
		to: '/pages/brands/icotek'
	}
	,
	{
		label: 'Lincoln',
		to: '/brands/featured/lincoln'
	},
	{
		label: 'Omron',
		to: '/brands/featured/omron'
	},
	{
		label: 'Oriental Motor',
		to: '/brands/featured/oriental-motor'
	},
	{
		label: 'Paccar',
		to: '/brands/featured/paccar'
	},
	{
		label: 'Parker',
		to: '/brands/featured/parker'
	},
	{
		label: 'Phoenix Contact',
		to: '/brands/featured/phoenix-contact'
	},
	{
		label: 'Rexroth',
		to: '/brands/featured/rexroth'
	},
	{
		label: 'Rittal',
		to: '/brands/featured/rittal'
	},
	{
		label: 'Ross',
		to: '/brands/featured/ross'
	},
	{
		label: 'Schmersal',
		to: '/brands/featured/schmersal'
	},
	{
		label: 'SMC',
		to: '/brands/featured/smc'
	},
	{
		label: 'Turck',
		to: '/brands/featured/turck'
	}
]

// const resourcesSubItems = [
//     {
//         label: 'Apps',
//         to: '/pages/apps'
//     },
//     {
//         label: 'Blog - Technically Speaking',
//         to: 'https://blog.airlinehyd.com/',
//         isExternalLink: true
//     },
//     {
//         label: 'Crossover Corner',
//         to: 'https://info.airlinehyd.com/crossover-corner',
//         isExternalLink: true
//     },
//     {
//         label: 'FAQ',
//         to: 'https://answers.airlinehyd.com/faq',
//         isExternalLink: true
//     },
//     {
//         label: 'Line Cards & Brochures',
//         to: '/pages/resources/linecards'
//     },
//     {
//         label: 'Manufacturer Catalogs',
//         to: '/pages/resources/catalog-request'
//     },
//     {
//         label: 'Knowledge Center',
//         to: '/pages/resources/knowledge-center'
//     },
//     {
//         label: 'SMC Product Configurator',
//         to: 'https://www.airlinehyd.com/srf/SmcUSA',
//         isExternalLink: true
//     },
//     {
//         label: 'Tech Traveler',
//         to: 'https://info.airlinehyd.com/book-tech-traveler',
//         isExternalLink: true
//     },
//     {
//         label: 'Youtube Channel',
//         to: 'https://www.youtube.com/channel/UCdZYpFsi2IES53d5BZr03fw/',
//         isExternalLink: true
//     },
//     {
//         label: 'Webinars',
//         to: '/pages/resources/webinars',
//     },
// ]

const aboutSubItems = [
	{
		label: 'About us',
		to: '/pages/about-us',
	},
	{
		label: 'Careers',
		to: '/pages/about/careers',
	},
	{
		label: 'Company History',
		to: '/pages/about/our-history',
	},
	{
		label: 'Culture corner',
		to: 'https://info.airlinehyd.com/culture-corner',
		isExternalLink: true
	},
	{
		label: 'Events',
		to: '/pages/about/events',
	},
	{
		label: 'Locations',
		to: '/pages/about/locations',
	},
	{
		label: 'Press Room',
		to: '/pages/about/news',
	},
	{
		label: 'Quality Policy',
		to: '/pages/about/quality-policy',
	},
	{
		label: 'Transactional Services',
		to: '/about/transactional-services',
	}
]

const contactSubItems = [
	{
		label: 'Contact Us',
		to: '/pages/contact/contact-us'
	},
	{
		label: 'Credit Application',
		to: '/pages/contact/credit-application'
	},
	{
		label: 'Framing Request',
		to: '/pages/contact/framing-request'
	},
	{
		label: 'Government Sales',
		to: '/pages/government-sales'
	},
]

const headerTabs = categories => [
	{
		label: 'Shop',
		to: '/categories',
		subItems: categories.map(({ name, urlSlug }) => ({
			label: name,
			to: `/categories/${urlSlug}/1`
		}))
	},
	{
		label: 'Services',
		to: '/pages/services',
		// subItems: servicesSubItems
	},
	{
		label: 'Industries',
		to: '/pages/industries',
		// subItems: industriesSubItems
	},
	{
		label: 'Brands',
		to: '/pages/brands',
		subItems: brandsSubItems
	},
	{
		label: 'Resources',
		to: '/pages/resources/resources',
		// subItems: resourcesSubItems
	},
	{
		label: 'About',
		to: '/pages/about',
		subItems: aboutSubItems
	},
	{
		label: 'Contact',
		to: '/pages/contact',
		subItems: contactSubItems
	}
]
