import React, { useState } from 'react'
import { HubSpotPost } from '../../setup/REST'
import styled from 'styled-components'

const Button = styled.button`
    border: 0;
    color: white;
    background-color: #246696;
    border-radius: 3px;
    padding: 3px 10px;
    font-size: 16px;
`
const Input = styled.input`
    margin: 0 10px;
`
const ThankYouMessage = styled.p`
    margin-bottom: 0;
    font-weight: bold;
    margin-left: 10px;
}
`
export default function HubSpotBlogSubscription() {
    const [email, setEmail] = useState('')
    const [submitted, setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const formUrl = 'https://api.hsforms.com/submissions/v3/integration/submit/8466844/eb49d36f-ebce-4de5-ac00-4bcc64563e16'

    function onSubmit() {
        if (!email.trim()) {
            setErrorMessage("Please enter your email address!");
            return;
        }

        const body = {
            "fields": [
                {
                    "objectTypeId": "0-1",
                    "name": "email",
                    "value": email
                }
            ]
        }

        HubSpotPost(formUrl, body)
            .then(response => {
                if (!response.ok) {
                console.log(response);
                }
                setSubmitted(true);
            })
            .catch(error => console.error(error));
    }

    function ValidationMessage({ message }) {
        return <p style={{ color: 'red', margin: '0 0 0 10px', fontSize: '12px' }}>{message}</p>;
    }
    return (
        <>
            {!submitted ? (
                <>
                    <Input
                        placeholder="your@email.com"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setErrorMessage('');
                        }}
                    />
                    <Button onClick={onSubmit}>Subscribe</Button>
                    {errorMessage && <ValidationMessage message={errorMessage} />}
                </>
            ) : (
                <ThankYouMessage>Thank you for subscribing!</ThankYouMessage>
            )}
        </>
    )

}