export function getApiPath(relative) {
    return process.env.REACT_APP_API_URL + relative;
}

export function handleError(response) {
    if (!response.ok) {
        if (response.status === 500) {
            return response.json().then(r => {console.log(r); throw new Error(r.detail)} )
        } else if(response.status === 403) {
            return response.json().then(r => { console.log(r); throw new Error("Permission Denied: " + r.detail)})
        }
    } 
    return response;
}

export function handle401(response, userContext) {
    if (!response.ok) {
        if (response.status === 401) {
            console.log("Received 401. Logging out...");
            userContext.setAlert("danger", "The login attempt failed");
            userContext.logoutUser();
        }
        throw new Error(response.statusText);
    } 
    return response;
}