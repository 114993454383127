
import { useAuth } from "react-oidc-context";
import { getApiPath } from "../api";


//Custom hook to be able to get the ambient "auth" context
export const useAirlineFetch = () => {
	const auth = useAuth();
	const airlinePost = (url, body)  => {
		console.log("useAirlineFetch.airlinePost to ", url, body);
		return AirlineFetch(url, Methods.POST, body, auth.user?.access_token);
	};
	const airlineGet = (url) => {
		console.log("useAirlineFetch.airlineGet to ", url);
		return AirlineFetch(url, Methods.GET, null, auth.user?.access_token);
	};
	const airlineFileUpload = (url, file) => {
		const data = new FormData();
  		data.append('file', file);
		console.log("useAirlineFetch.airlineFileUpload to ", url,data);
		const headers = {};

		if (auth.user?.access_token) {
			headers["Authorization"] = `Bearer ${auth.user?.access_token}`;
		}
		return fetch(url, { method: Methods.POST, body: data,  headers });
	};

	return { airlinePost, airlineGet, airlineFileUpload };
}

/**
 * runs a fetch in a safe way
 * @param {string} url - function will not run *getApiPath* as it can be used for other fetches as well
 * @param {string} method - use REST.Methods to get exact string without . defaults to get
 * @param {object} body -  body of the call defaults to empty
 * @returns a promise which can be followed with a .then
 */
export function Fetch(url, method = Methods.GET, body = null, accessToken = null) {
    if (url.includes(getApiPath())) {
        // this is an airline call requires tokens
        if (accessToken) {
            return fetch(url, {
                method: method,
                headers: {
                    authorization: accessToken ? `Bearer ${accessToken}` : null
                },
                body: body,
            });
        }
    }
    return fetch(url, { method: method, body: body });
}
/**
 * runs a fetch in a safe way
 * @obsolete
 * @param {string} url - will run a getAPIPath for use on internal tooling only
 * @param {string} method - use REST.Methods to get exact string without . defaults to get
 * @param {object} body -  body of the call defaults to empty
 * @returns a promise which can be followed with a .then
 */
function AirlineFetch(url, method = Methods.GET, body = null, accessToken = null) {
    if (!url.includes(getApiPath(''))) {
        url = getApiPath(url);
    }
	if(typeof body === 'object' && body !== null && !Array.isArray(body)) body = JSON.stringify(body);

	const headers = { "Content-Type": "application/json" };

    if (accessToken) {
		headers["Authorization"] = accessToken ? `Bearer ${accessToken}` : null;
    }

    return fetch(url, { method: method, body: body, headers });
}

export const Methods = {
    GET: "GET",
    HEAD: "HEAD",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    CONNECT: "CONNECT",
    TRACE: "TRACE",
    PATCH: "PATCH",
};

export function HubSpotPost(url, body = null) {
    return fetch(url, { method: Methods.POST, body: JSON.stringify(body), headers: { "Content-Type": "application/json" } });
}
