import React, { useContext, useEffect, useState } from 'react'
import { AreaContentRow, AreaHeading, HorizontalPackCenter, LayoutArea, StickyArea, StyledCheckbox } from '../styledComponents';
import { Backspace, CheckCircle, Feedback, FmdBad } from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';
import OrderSummaryTable from '../components/OrderSummaryTable';
import MyContext from 'setup/context';
import { useCheckout2 } from 'setup/CheckoutProviderFields';
import { ClickableErrorAlert, ErrorAlert } from 'styles/alerts';
import styled from 'styled-components';
import { ButtonRed } from 'styles/buttons';
import NcnrAcknowledgement from '../components/NcnrAcknowledgement';
import Tooltip from '@mui/material/Tooltip';
import { gtag } from 'ga-gtag';
import ReactLoading from 'react-loading'

const Columns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
`

const CheckoutLoader = styled(ReactLoading)`
	margin-top: -10px;
	margin-bottom: auto;
	margin-left: 0;
	margin-right: 0;
`

/* <StatusIcon isSectionValid={} validText= invalidText= sectionTitle= /> */
export const StatusIcon = function (props) {
	const { sectionTitle,
		isSectionValid,
		validText,
		invalidText,
	} = props;
	if (isSectionValid) {
		return <CheckCircle titleAccess={isSectionValid ? validText : invalidText} label={sectionTitle} htmlColor='green' />
	} else {
		return <FmdBad titleAccess={invalidText} label={sectionTitle} htmlColor={'#CDC'} />
	}
}

export default function Checkout2Summary() {
	const { userInfo } = useContext(MyContext);
	const {
		loading,
		checkoutInitializeDataIsLoading,
		checkoutEditableFields,
		checkoutSaveIsLoading,
		isApproveOrder,
		setIsApproveOrder,
		validationStatus,
		submitOrder,
		inPageRefs,
		openEditors,
		getFirstOpenEditorInPageRef,
		acknowledgeNcnr,
		containNcnrItem,
		setAcknowledgeNcnr,
	} = useCheckout2();

	function calcHasOpenEditor() {
		return Object.values(openEditors).some(item => item === true);
	}

	const [hasOpenEditor, setHasOpenEditor] = useState(calcHasOpenEditor());
	useEffect(() => {
		setHasOpenEditor(calcHasOpenEditor());
	}, [openEditors]);

	useEffect(() => {
		if (!containNcnrItem) {
			setAcknowledgeNcnr(true)
		}
	}, [containNcnrItem])

	const submitButtonText = checkoutEditableFields.IsQuote ? 'Submit Quote' : 'Submit Order';
	const isDisabled = checkoutInitializeDataIsLoading || checkoutSaveIsLoading || validationStatus.IsReadyToCheckout === false || hasOpenEditor || (validationStatus.IsPOValid?.Status !== "Valid") || (containNcnrItem && !userInfo?.isAirlineEmployee && !acknowledgeNcnr);

	function submitMessage() {
		const rtn = [];
		if (containNcnrItem && !acknowledgeNcnr) {
			rtn.push("Please acknowledge NCNR statement above");
		}
		if (validationStatus.IsPOValid?.Status === "Empty") {
			rtn.push("Po number is empty");
		}
		if (!validationStatus.IsPOValid?.Status === "Duplicate") {
			rtn.push("Duplicate Po number");
		}
		return rtn.join("\n");
	}
	const disableMessage = submitMessage()
	const contactValid = validationStatus.IsContactValid && !openEditors.contact;
	const shipToValid = validationStatus.IsShipToValid && !openEditors.shippingAddress && !openEditors.shippingOptions;
	const billToValid = validationStatus.IsBillToValid && !openEditors.billing && !openEditors.billingAddress;
	const paymentValid = validationStatus.IsPaymentValid && !openEditors.billing

	useEffect(() => {
		if (contactValid) {
			gtag('event', 'add_contact_info', {
			})
		}
	}, [contactValid])

	useEffect(() => {
		if (shipToValid) {
			gtag('event', 'add_shipping_info', {

			})
		}
	}, [shipToValid])
	useEffect(() => {
		if (billToValid) {
			gtag('event', 'add_billing_info', {

			})
		}
	}, [billToValid])
	useEffect(() => {
		if (paymentValid) {
			gtag('event', 'add_payment_info', {

			})
		}
	}, [paymentValid])


	return (
		<StickyArea>
			<AreaHeading>
				<h1>Summary</h1> {loading && <CheckoutLoader type={'bubbles'} color={'#bbb'} height="32px" />}
			</AreaHeading>
			<LayoutArea>
				<AreaContentRow>
					<Columns>
						<Column>
							<Stack style={{ cursor: "pointer" }} direction={'row'} onClick={() => inPageRefs.contactSectionRef.current.scrollIntoView({ behavior: 'smooth' })}>
								<StatusIcon isSectionValid={contactValid} validText='Contact section is valid' invalidText='Contact section needs attention' sectionTitle='Contact' />
								<label>Contact</label>
							</Stack>
							{validationStatus.NewAccountStatus.Status !== 'Disabled' /*Only show this if NewAccountStatus indicates action required there */ &&
								<Stack style={{ cursor: "pointer" }} direction={'row'} onClick={() => inPageRefs.newAccountSectionRef.current.scrollIntoView({ behavior: 'smooth' })}>
									<StatusIcon isSectionValid={validationStatus.NewAccountStatus.Status === "Ok"} validText='Create new account' invalidText='New Account Password needs attention' sectionTitle='New Account is valid' />
									<label>Password</label>
								</Stack>
							}
							<Stack style={{ cursor: "pointer" }} direction={'row'} onClick={() => inPageRefs.shippingAddressSectionRef.current.scrollIntoView({ behavior: 'smooth' })}>
								<StatusIcon isSectionValid={shipToValid} validText='Ship To section is valid' invalidText='Ship To section needs attention' sectionTitle='Ship to is Valid' />
								<label>Ship To</label>
							</Stack>
							{!checkoutEditableFields.IsQuote &&
								<Stack style={{ cursor: "pointer" }} direction={'row'} onClick={() => inPageRefs.shippingChoicesSectionRef.current.scrollIntoView({ behavior: 'smooth' })}>
									<StatusIcon isSectionValid={validationStatus.IsShippingOptionValid} validText='A shipping option is selected' invalidText='A shipping option is not selected' sectionTitle='Shipping Option is Selected' />
									<label>Shipping</label>
								</Stack>}
							{!checkoutEditableFields.IsQuote &&
								<Stack style={{ cursor: "pointer" }} direction={'row'} onClick={() => inPageRefs.paymentSectionRef.current.scrollIntoView({ behavior: 'smooth' })}>
									<StatusIcon isSectionValid={validationStatus.IsPaymentValid} validText='Payment method is valid' invalidText='Payment method needs attention' sectionTitle='Payment is Valid' />
									<label>Payment</label>
								</Stack>}
							<Stack style={{ cursor: "pointer" }} direction={'row'} onClick={() => inPageRefs.billingAndOtherSectionRef.current.scrollIntoView({ behavior: 'smooth' })}>
								<StatusIcon isSectionValid={billToValid} validText='Bill To section is valid' invalidText='Bill To section needs attention' sectionTitle='Bill to is Valid' />
								<label>Bill To</label>
							</Stack>
						</Column>
						<Divider flexItem orientation='vertical'></Divider>
						<Column>
							<OrderSummaryTable />
						</Column>
					</Columns>
				</AreaContentRow>
				{userInfo?.isImpersonatorUser && !checkoutEditableFields.IsQuote &&
					<AreaContentRow>
						<label htmlFor="isApproveOrder">Approve Order</label>
						<StyledCheckbox
							id="isApproveOrder"
							name="isApproveOrder"
							type="checkbox"
							checked={isApproveOrder}
							onChange={(e) => setIsApproveOrder(e.target.checked)}
						/>
					</AreaContentRow>}
				{/* If we're ready to checkout, but the user has some kind of pending edit, prevent the checkout */}
				{validationStatus.IsReadyToCheckout === true && hasOpenEditor &&
					<AreaContentRow>
						<ClickableErrorAlert onClick={() => getFirstOpenEditorInPageRef().current.scrollIntoView({ behavior: 'smooth' })}>There are unsaved changes. Please save before finishing checkout. <br />&raquo; Go there &laquo;</ClickableErrorAlert>
					</AreaContentRow>
				}
				{/* If we're ready to checkout, but the user is using duplicate PO Number, prevent the checkout */}
				{(validationStatus.IsPOValid?.Status === "Duplicate")
					?
					<AreaContentRow>
						<ClickableErrorAlert onClick={() => document.getElementById('poNumber').scrollIntoView({ behavior: 'smooth' })}>Please confirm duplicate PO Number usage. <br />&raquo; Go there &laquo;</ClickableErrorAlert>
					</AreaContentRow>
					:
					((validationStatus.IsPOValid?.Status && validationStatus.IsPOValid.Status !== "Valid") &&
						<AreaContentRow>
							<ClickableErrorAlert onClick={() => document.getElementById('poNumber').scrollIntoView({ behavior: 'smooth' })}>Please check the PO Number<br />&raquo; Go there &laquo;</ClickableErrorAlert>
						</AreaContentRow>
					)
				}
				{validationStatus.IsNotReadyToCheckoutReasons?.length > 0 &&
					validationStatus.IsNotReadyToCheckoutReasons.map((msg, idx) => {
						return <AreaContentRow key={idx}>
							<ErrorAlert>{msg}</ErrorAlert>
						</AreaContentRow>
					})
				}
				{!userInfo?.isAirlineEmployee && <NcnrAcknowledgement />}
				<AreaContentRow>
					<HorizontalPackCenter>
						<Tooltip title={disableMessage}>
							<span>
								<ButtonRed
									id={`CheckoutBtn`}
									variant="contained"
									color="primary"
									disabled={isDisabled}
									onClick={submitOrder}
								>
									{submitButtonText}
								</ButtonRed>
							</span>
						</Tooltip>
					</HorizontalPackCenter>
				</AreaContentRow>
			</LayoutArea>
		</StickyArea>);
}