import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import React from 'react'
import { useAuth } from 'react-oidc-context';
import { onError } from '@apollo/client/link/error'

export default function AuthApolloProvider({children}) {
	const auth = useAuth();

	const httpLink = new HttpLink({
		uri: `${process.env.REACT_APP_API_URL}/graphql`,
	});
	
	const unauthHandler = () => {
		//If we received an unauthorized result from the server, 
		// and we think we're logged in, we need to logout.
		console.log("WARNING. Received an Unauthorized Result from GQL Endpoint. Did our SSO access_token expire?");
	}

	const errorLink = onError(({ graphQLErrors, networkError }) => {
		if (graphQLErrors) {
			console.log("graphQLErrors", graphQLErrors)
			if (graphQLErrors.some(e => e.extensions.code === "AIRLINE_UNAUTHENTICATED")) {
				unauthHandler();
			}
		} else if (networkError) {
			if (networkError.statusCode === 401) {
				unauthHandler();
			}
		}
	})
	// Setup the header for the request
	const middlewareAuthLink = new ApolloLink((operation, forward) => {
		const token = auth?.user?.access_token;
		
		//Attach the JWT tokens to every request.
		if (token) {
			operation.setContext({
				headers: {
					authorization: token ? `Bearer ${token}` : null,
				}
			});
		}

		return forward(operation);
	});

	const client = new ApolloClient({
		link: ApolloLink.from([
			errorLink,
			middlewareAuthLink,
			/*AirlineHydAPI does NOT issue tokens, only validates them*/
			httpLink
		]),
		cache: new InMemoryCache()
	});


	return <ApolloProvider client={client} children={children} />
}
