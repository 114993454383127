import React, { useContext } from 'react'
import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import { useAuth } from "react-oidc-context";
import { Navigate } from "react-router";
import Context from 'setup/context';

const webBaseUrl = process.env.REACT_APP_SITE_URL;

const oidcConfig = {
	//authority: "https://testsso.airlinehyd.com",
	authority: process.env.REACT_APP_SSO_AUTHORITY,
	client_id: process.env.REACT_APP_SSO_CLIENT_ID,
	redirect_uri: webBaseUrl,
	userStore: new WebStorageStateStore({ store: window.localStorage }),
	//onSigninCallback: (user) => { console.log("SIGN IN", user); oidcCallback(user); },
	stopCheckSessionOnError: false,
	validateSubOnSilentRenew: true,
	includeIdTokenInSilentRenew: true,
	automaticSilentRenew: true,
	silent_redirect_uri: webBaseUrl + '/SilentRenew.html',
	
	onSignoutCallback: () => { console.log("SIGN OUT"); },
	post_logout_redirect_uri: webBaseUrl + "/sign-out",
	scope: 'openid profile email api',
	loadUserInfo: true,
	revokeAccessTokenOnSignout: true,
	monitorSession: true
};

/*When this component is shown, instruct oidc to log the user out
 This will update the auth.user object, and ContextProvider will react and clear 
 whatever session data it needs to.
*/
const SignOutPage = () => {
	const context = useContext(Context);
	const auth = useAuth();
 	auth.removeUser().then(() => context.userSignedOut());
	return <Navigate to="/" />;
}

export {
    oidcConfig,
	UserManager,
	SignOutPage
};