import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import CartItems from './areas/CartItems'
import ContactInfo from './areas/ContactInfo'
import OtherInfo from './areas/BillingAndOther'
import PaymentInfo from './areas/PaymentMethod'
import QuoteInput from './components/QuoteInput'
import {  ButtonRed } from 'styles/buttons'
import { MainLayout, ConfigColumn, StickyCheckoutContainer, Label } from './styledComponents'
import { useLocation } from 'react-router'
import Shipping from './areas/ShippingChoices'
import Modal from 'pageComponents/_common/modal'
import Loader from 'pageComponents/_common/loader'
import MyContext from 'setup/context'
import CheckoutProvider, { useCheckout2 } from 'setup/CheckoutProviderFields'
import ContactInfoAnon from './areas/ContactInfoAnon'
import ShippingAddress from './areas/ShippingAddress'
import Checkout2Summary from './areas/Checkout2Summary'
import { Link } from 'react-router-dom'

export default function Checkout2(props) {
    return (<CheckoutProvider>
        <Checkout2Initializer />
    </CheckoutProvider>);
}

function Checkout2Initializer() {
    const location = useLocation();
    const {
        initializeCheckout,
    } = useCheckout2();
    //Initialize the provider for checkout2. Pass whether we're trying to create a quote or not
    useEffect(() => {
        initializeCheckout(location.pathname === '/create-quote2');
    },[]);
    
    return (<Checkout2Impl />);
}

function Checkout2Impl() {
    const { userInfo } = useContext(MyContext);
    const {
		checkoutIsSubmitting,
        checkoutInitializeDataIsLoading,
		isCheckoutInitializeFailed,
		checkoutInitializeData,
        checkoutEditableFields,
        showAlert,
        setShowAlert,
        alertContent,
    } = useCheckout2();

    return (
        <MainLayout>
            <Helmet>
                <title>Checkout | Airline Hydraulics</title>
            </Helmet>
			<Modal open={showAlert} onClose={() => setShowAlert(false)}>
				{alertContent}
				<ButtonRed style={{ margin: "10px auto" }} onClick={() => setShowAlert(false)}>Okay</ButtonRed>
			</Modal>
			{isCheckoutInitializeFailed === true && <Link to="/">Go back</Link>} 
            {(checkoutInitializeDataIsLoading === true) && <Loader />}
			{(checkoutIsSubmitting === true) && <span>Please wait while we submit your order<br /><Loader /></span>}
            {checkoutInitializeDataIsLoading === false && checkoutIsSubmitting === false && checkoutInitializeData && <>
                <h1 style={{ width: "100%" }}>Checkout</h1>
                {!userInfo && <h3>You are checking out as a guest. You can create an account below.</h3>}
                <StickyCheckoutContainer>
                    <ConfigColumn>
                        {checkoutEditableFields.IsQuote && (
                            <QuoteInput />
                        )}
                        {userInfo &&
                            <ContactInfo />
                        }
                        {!userInfo &&
                            <ContactInfoAnon />
                        }
                        <ShippingAddress />
                        {!checkoutEditableFields.ShippingChoices_IsCollect && !checkoutEditableFields.IsQuote &&
                            <Shipping />
                        }
                        <CartItems />
                        <PaymentInfo />
                        <OtherInfo />
                    </ConfigColumn>
                    <ConfigColumn>
                        <Checkout2Summary />
                    </ConfigColumn>
                </StickyCheckoutContainer>
            </>}
        </MainLayout>
    )
}
