import React, { useEffect, useState } from 'react'
import { Checkbox } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from '../../_common/modal'
import styled from 'styled-components'
import { ButtonBlack, ButtonRed } from '../../../styles/buttons'

const DivRow = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  flex-wrap: wrap;
`

const DivCol = styled.div`
    flex-direction: column;
    align-content: stretch;
`

const DivButtonRow = styled.div`
  display: flex;
  width: 300px;
  max-width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`
const DivItem = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`

const Label = styled.label`
  margin: 0;
  font-size: 12px;
  font-style: italic;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 24px;
  width: 100%;
  h4 {
    font-family: ProximaBold;
  }
  p {
    font-family: Proxima;
    text-align: center;
  }
  button {
    margin-top: 8px;
  }
`

const MarginTopDiv = styled.div`
  margin-top: 10px;
`

const Space = styled.div`
  display: inline-block;
  width: 16px;
`

const Textarea = styled.textarea`
    width: 300px; 
    max-width: 100%;
`

const customerVisibleAreas = [
	'Print Packing Lists',
	'Print Invoices',
	'Print Order Acknowledgements',
];

//This object provides an editor for note areas and optionally a note itself. 
//When type='order', a note box is not shown, only the editor for the note areas.
//Do not implement "removeNote" when using in 'order' mode.
export default function NoteAndAreaModal(props) {
	const {
		saveCallback,
		removeNote,
		values,
		isOpen,
		hide,
		allowedNoteAreas,
		type
	} = props;

	const defaultAreas = type === 'order' ? [] : ['Order Entry'];
	const [areas, setAreas] = useState(defaultAreas);
	const [noteInput, setNoteInput] = useState('');

	useEffect(() => {
		if (values?.note) {
			setNoteInput(values.note);
		}
		if (values?.targetAreas) {
			setAreas(values.targetAreas);
		}
	}, [values])

	const areaOptions = allowedNoteAreas?.sort().sort((a) => customerVisibleAreas.includes(a) ? -1 : 1)
		.map(a => {
			function handleChange(e) {
				const checked = e.target.checked;
				if (checked) {
					setAreas([...areas, a]);
				} else {
					setAreas(areas.filter(s => s !== a));
				}
			}

			return (
				<div key={a} style={{ width: 300 }}>
					{customerVisibleAreas.includes(a) ? <FontAwesomeIcon icon='eye' /> : <Space />}
					<Checkbox checked={areas.includes(a)} onChange={handleChange} />{a}
				</div>
			);
		});

	function groupOptions(options) {
		const grouped = [];
		for (let i = 0; i < options?.length; i += 3) {
			const group = options.slice(i, i + 3);
			grouped.push(<DivCol key={i}>{group}</DivCol>);
		}
		return grouped;
	}

	const groupedOptions = groupOptions(areaOptions);

	function handleClose() {
		setAreas(defaultAreas);
		setNoteInput('');
		hide();
	}

	function handleAddNote() {
		saveCallback(values.index, areas, noteInput, values.noteIdx);
		handleClose();
	}

	function handleRemoveNote() {
		removeNote(values.index, values.noteIdx);
		handleClose();
	}

	const saveDisabled = (areas.length === 0 || noteInput.length === 0) && values?.type !== 'order';

	return (
		<Modal open={isOpen} onClose={handleClose} contentStyle={{ width: '90vw', maxHeight: '90vh', overflowY: 'scroll', borderRadius: 3 }}>
			<Container>
				<h4>Note Location</h4>
				<DivRow>
					{groupedOptions}
				</DivRow>
				{type !== 'order' && (
					<DivItem>
						<Label>Note: </Label>
						<Textarea value={noteInput} onChange={(e) => setNoteInput(e.target.value)} rows={3} />
					</DivItem>
				)}
				<MarginTopDiv></MarginTopDiv>
				<DivButtonRow>
					<ButtonBlack onClick={handleClose}>Cancel</ButtonBlack>
					{values?.noteIdx !== undefined && <ButtonRed onClick={handleRemoveNote}>Delete</ButtonRed>}
					<ButtonRed onClick={handleAddNote} disabled={saveDisabled}>Save</ButtonRed>
				</DivButtonRow>
			</Container>
		</Modal>
	);
}