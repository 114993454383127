import React, { useContext, useState } from 'react'
import ContactSelect from '../components/contact/ContactSelect'
import { useCheckout2 } from 'setup/CheckoutProviderFields'
import { AreaContentRow, AreaHeading, HeaderActionButton, Area, LayoutArea, Grower } from '../styledComponents'
import ContactViewEdit from '../components/contact/ContactViewEdit'
import MyContext from 'setup/context'
import { StatusIcon } from './Checkout2Summary'


export default function ContactInfo() {
	const {
		checkoutEditableFields,
		checkoutInitializeData,
		validationStatus,
		inPageRefs,
		openEditors,
		showContactEditor,
		setShowContactEditor,
	} = useCheckout2();
	const { userInfo } = useContext(MyContext);

	const [showContactPicker, setShowContactPicker] = useState(false);

	const [selectedContact, setSelectedContact] = useState({
		Title: checkoutEditableFields.SelectedContact_Title,
		FirstName: checkoutEditableFields.SelectedContact_FirstName,
		LastName: checkoutEditableFields.SelectedContact_LastName,
		Phone: checkoutEditableFields.SelectedContact_Phone,
		PhoneExt: checkoutEditableFields.SelectedContact_PhoneExt,
		Email: checkoutEditableFields.SelectedContact_Email,
		P21Id: checkoutEditableFields.SelectedContact_P21Id,
	});

	function selectContact(cont) {
		console.log("setSelectedContact", cont);
		setSelectedContact({ ...cont });
		setShowContactEditor(false);
	}

	function newContactCallback() {
		setSelectedContact({
			P21Id: 0,
			Title: '',
			FirstName: '',
			LastName: '',
			Phone: '',
			PhoneExt: '',
			Email: ''
		});

		setShowContactEditor(true);
	}

	const contactValid = validationStatus.IsContactValid && !openEditors.contact;

	return (
		<Area>
			<AreaHeading ref={inPageRefs.contactSectionRef}>
				<h1>Contact</h1>
				<Grower>
					{userInfo && userInfo.isAirlineEmployee &&
						<HeaderActionButton id={`contacts-ListBtn`} onClick={() => setShowContactPicker(!showContactPicker)}>
							{showContactPicker ? 'Done' : 'Contacts...'}
						</HeaderActionButton>
					}
				</Grower>
				<StatusIcon isSectionValid={contactValid} validText='Contact section is valid' invalidText='Contact section needs attention' sectionTitle='Contact' />
			</AreaHeading>
			<LayoutArea>
				{showContactPicker ? (
					<ContactSelect
						hide={() => { setShowContactPicker(false); setShowContactEditor(true); }}
						title="Saved Contacts"
						contactList={checkoutInitializeData.Contacts}
						contactSelectedCallback={(cont) => selectContact(cont)}
						newContactCallback={newContactCallback}
						currentSelectedContactId={selectedContact.P21Id}
					/>
				) : (
					<AreaContentRow>
						<ContactViewEdit
							contact={selectedContact}
							contactUpdatedCallback={(cont) => selectContact(cont)}
							showContactEditor={showContactEditor}
							setShowContactEditor={setShowContactEditor}
						/>
					</AreaContentRow>
				)}
			</LayoutArea>
		</Area>
	)
}