import React from 'react'
import { SumTable } from '../styledComponents'
import { moneyFormatter } from 'pageComponents/_common/util/formatters'
import { useCheckout2 } from 'setup/CheckoutProviderFields';
import Required from 'pageComponents/_common/required';

export default function OrderSummaryTable(props) {
    const { hideShipping } = props;
    const { validationStatus } = useCheckout2();

    return (
        <SumTable>
            <tbody>
                <tr><td>Item Subtotal:</td><td>{moneyFormatter.format(validationStatus.SubTotal)}</td></tr>
                <tr><td>Shipping and Handling:</td><td>{hideShipping || (validationStatus.ShippingTotal === null) ? (validationStatus.IsShippingCollect === true ? 'COLLECT' : '(TBD)') : moneyFormatter.format(validationStatus.ShippingTotal)}</td></tr>
                <tr><td>Tax Subtotal:</td><td>{validationStatus.TaxTotal === null ? '(TBD)' : moneyFormatter.format(validationStatus.TaxTotal)}</td></tr>
                <tr style={{ borderTop: "1px solid black" }}><td>Total:</td><td style={{ fontWeight: "bold", fontSize: "1.6em" }}>{validationStatus.OrderTotal === null ? '(TBD)' : moneyFormatter.format(validationStatus.OrderTotal)}</td></tr>
                <tr><td colSpan={2}  style={{textAlign: 'center'}}><span style={{fontStyle: 'italic', textAlign: 'center'}}><Required/> Note: Shipping and tax are estimates, you will be charged as your order is packaged and ships. If you request your order to ship with multiple deliveries, you will be charged for the portions of the order that have shipped, at that time.</span></td></tr>
            </tbody>
        </SumTable>)
}