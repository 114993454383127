import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import ShippingScheduleTable from './ShippingScheduleTable'
import ReactDatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import {
	HorizontalPackShip, Container, DivRow, Pinfo, InlineButtonBlack, InlineButtonRed,
	FlexTable, FlexTableData, FlexTableRow, Textarea,
} from '../styledComponents'
import { useCheckout2 } from 'setup/CheckoutProviderFields'
import { DATE_FORMAT_DISPLAY } from 'pageComponents/_common/constants/formattingConstants'
import MyContext from 'setup/context'
import { Tooltip } from '@mui/material'
import { InfoRounded, RestartAlt } from '@mui/icons-material';

export default function AdditionalShippingOptions(props) {
	const {
		hide
	} = props

	const {
		checkoutInitializeData,
		checkoutEditableFields,
		setCheckoutEditableFields,
	} = useCheckout2();

	const { userInfo } = useContext(MyContext);

	const [additionalOptions, setAdditionalOptions] = useState(getInitalState());

	function getInitalState() {
		return {
			selectedPackingBasis: checkoutEditableFields.ShippingChoices_PackingBasis,
			isRush: checkoutEditableFields.ShippingChoices_IsRush,
			notBeforeDate: checkoutEditableFields.ShippingChoices_NotBeforeDate || new Date(),
			selectedSpecialPaperworkRequested: checkoutEditableFields.ShippingChoices_SpecialPaperworkRequest,
			isCollect: checkoutEditableFields.ShippingChoices_IsCollect,
			collectAccountNumber: checkoutEditableFields.ShippingChoices_CollectAccount,
			collectCarrier: checkoutEditableFields.ShippingChoices_SelectedCollectCarrier,
			shippingSchedule: checkoutEditableFields.ShippingChoices_ShippingSchedule,
			deliveryInstructions: checkoutEditableFields.ShippingChoices_DeliveryInstructions
		};
	}

	const defaultRequestedDate = new Date()
	defaultRequestedDate.setDate(defaultRequestedDate.getDate() + 2)

	function save() {
		//Persist edits to the context
		setCheckoutEditableFields({
			...checkoutEditableFields,
			ShippingChoices_PackingBasis: additionalOptions.selectedPackingBasis ? additionalOptions.selectedPackingBasis : checkoutEditableFields.ShippingChoices_PackingBasis,
			ShippingChoices_IsCollect: additionalOptions.isCollect,
			ShippingChoices_IsRush: additionalOptions.isRush,
			ShippingChoices_NotBeforeDate: additionalOptions.notBeforeDate,
			ShippingChoices_SpecialPaperworkRequest: additionalOptions.selectedSpecialPaperworkRequested,
			ShippingChoices_CollectAccount: additionalOptions.isCollect ? additionalOptions.collectAccountNumber : null,
			ShippingChoices_SelectedCollectCarrier: additionalOptions.collectCarrier,
			ShippingChoices_ShippingSchedule: additionalOptions.shippingSchedule,
			ShippingChoices_DeliveryInstructions: additionalOptions.deliveryInstructions
		});
		hide();
	}

	function cancel() {
		setAdditionalOptions(getInitalState());
		hide();
	}

	function setItemRequestedDate(lineNumber, requestedDate) {
		let schedCopy = structuredClone(additionalOptions.shippingSchedule);
		const idx = schedCopy.findIndex(s => s.LineNumber === lineNumber);
		if (idx > -1) {
			schedCopy[idx].RequestedDate = requestedDate;
		} else {
			schedCopy = [...schedCopy, { lineNumber, requestedDate }]; //These might need to do LineNumber: lineNumber
		}

		setAdditionalOptions({ ...additionalOptions, shippingSchedule: schedCopy });
	}

	const ShipmentStyleDescription = () => {
		return <Pinfo>{checkoutInitializeData.PackingBasisList.find(ss => ss.Value === additionalOptions.selectedPackingBasis)?.HelpText}</Pinfo>
	}

	return (
		<Container>
			<h4>Additional Shipping Options</h4>
			<h6 style={{ borderBottom: "1px solid black", paddingBottom: "10px" }}>These advanced options can help control the processing and delivery of your order, based on your needs</h6>
			<FlexTable>
				<FlexTableRow>
					<FlexTableData>
						<label htmlFor="packingBasis">How do you want your order to ship?</label>
					</FlexTableData>
					<FlexTableData>
						<select id="packingBasis" onChange={(e) => setAdditionalOptions({ ...additionalOptions, selectedPackingBasis: e.target.value })} value={additionalOptions.selectedPackingBasis}>
							{checkoutInitializeData.PackingBasisList.map((ss, idx) => <option key={ss.Value} value={ss.Value}>{ss.Label}</option>)}
						</select>
					</FlexTableData>
				</FlexTableRow>
				<FlexTableRow>
					<ShipmentStyleDescription />
				</FlexTableRow>
				{additionalOptions.selectedPackingBasis === "ScheduledPartial" && (
					<ShippingScheduleTable {...{ cartItems: checkoutInitializeData.ShoppingCartItems, setItemRequestedDate, modalShippingSchedule: additionalOptions.shippingSchedule }} />
				)}
				{!!(userInfo?.isImpersonatorUser) && <>
					<FlexTableRow>
						<FlexTableData>
							<label htmlFor="isRush">Rush Processing Requested?</label>
						</FlexTableData>
						<FlexTableData>
							<input id="isRush" type="checkbox" checked={additionalOptions.isRush} onChange={(e) => setAdditionalOptions({ ...additionalOptions, isRush: e.target.checked })} />
						</FlexTableData>
					</FlexTableRow>
				</>}
				{!!(userInfo) ? (<>
					<FlexTableRow>
						<FlexTableData>
							<label htmlFor="notBeforeDate">Do Not Ship Before Date <Tooltip arrow
									title={(
										<span style={{ fontSize: '12px', color: 'white', lineHeight: '18px' }}>
											This applies to the order in general. If you select Schedule By Line, the greater of either the line date or do not ship before date will apply.
										</span>
									)}>
									<InfoRounded fontSize='0.7rem' color='info' />
								</Tooltip>
							</label>
						</FlexTableData>
						<FlexTableData>
							<HorizontalPackShip>
								<ReactDatePicker
									id="notBeforeDate"
									dateFormat={DATE_FORMAT_DISPLAY}
									selected={additionalOptions.notBeforeDate}
									onChange={(date) => setAdditionalOptions({ ...additionalOptions, notBeforeDate: date })} />
								<RestartAlt onClick={() => setAdditionalOptions({ ...additionalOptions, notBeforeDate: checkoutEditableFields.ShippingChoices_NotBeforeDate })} style={{ cursor: "pointer", marginLeft: "5px" }} title="Reset" />
							</HorizontalPackShip>
						</FlexTableData>
					</FlexTableRow>
					<FlexTableRow>
						<FlexTableData>
							<label htmlFor="specialPaperworkRequested">Special Paperwork Requested?</label>
						</FlexTableData>
					</FlexTableRow>
					<FlexTableRow>
						<FlexTableData>
							<select style={{ marginLeft: "20px" }} id="specialPaperworkRequested" onChange={(e) => setAdditionalOptions({ ...additionalOptions, selectedSpecialPaperworkRequested: e.target.value })} value={additionalOptions.selectedSpecialPaperworkRequested}>
								{checkoutInitializeData.SpecialPaperworkKinds.map((opt, idx) => <option key={opt.Value} value={opt.Value}>{opt.Label}</option>)}
							</select>
						</FlexTableData>
					</FlexTableRow>
					<FlexTableRow>
						<FlexTableData>
							<label htmlFor="shipCollect">Ship Collect?</label>
						</FlexTableData>
						<FlexTableData>
							<input
								id="shipCollect"
								type="checkbox"
								checked={additionalOptions.isCollect}
								onChange={(e) => setAdditionalOptions({ ...additionalOptions, isCollect: e.target.checked })}
							/>
						</FlexTableData>
					</FlexTableRow>
					{additionalOptions.isCollect && <>
						<FlexTableRow>
							<FlexTableData>
								<label htmlFor="collectAcct">Collect Account Number</label>
							</FlexTableData>
							<FlexTableData>
								<input
									id="collectAcct"
									type="text"
									value={additionalOptions.collectAccountNumber}
									onChange={(e) => setAdditionalOptions({ ...additionalOptions, collectAccountNumber: e.target.value })}
								/>
							</FlexTableData>
						</FlexTableRow>
						<FlexTableRow>
							<FlexTableData>
								<label htmlFor="collectCarrier">Collect Carrier</label>
							</FlexTableData>
						</FlexTableRow>
						<FlexTableRow>
							<FlexTableData>
								<select id="collectCarrier" onChange={(e) => setAdditionalOptions({ ...additionalOptions, collectCarrier: e.target.value })} style={{ marginLeft: "20px" }}
									value={additionalOptions.collectCarrier || ''}>
									<option value="" disabled hidden>Select Carrier...</option>
									{checkoutInitializeData.CollectCarriers.map((opt, idx) => <option key={opt.Value} value={opt.Value}>{opt.Label}</option>)}
								</select>
							</FlexTableData>
						</FlexTableRow>
					</>}
				</>) : (
					<div>
						Additional options such as collect shipping, rush processing and more are available with an
						<Link to='/signup'> account.</Link>
					</div>
				)}
				<FlexTableRow>
					<FlexTableData>
						<label htmlFor="deliveryInstr">Delivery Instructions (Dock, Access Code, ATTN:, etc)</label>
					</FlexTableData>
					<FlexTableData>
						<Textarea
							id="deliveryInstr"
							placeholder="(optional)"
							value={additionalOptions.deliveryInstructions}
							rows={3}
							onChange={(e) => setAdditionalOptions({ ...additionalOptions, deliveryInstructions: e.target.value })}
						/>
					</FlexTableData>
				</FlexTableRow>
			</FlexTable>
			<DivRow>
				<InlineButtonBlack id={`AddlShpInstr-CancelBtn`} onClick={() => cancel()}>Cancel</InlineButtonBlack>
				<InlineButtonRed id={`AddlShpInstr-SaveBtn`} onClick={() => save()}>Save</InlineButtonRed>
			</DivRow>
		</Container>
	)
}
