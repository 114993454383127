import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { ButtonBlack } from 'styles/buttons'
import {
	AreaContentRow, AreaHeading, Bold, HorizontalPack, Area, Textarea, FlexInline,
	FullWidth, EyeSpace, FlexChild, LayoutArea, StyledCheckbox
} from '../styledComponents'
import DocumentLinkModal from 'pageComponents/ShoppingCart/uiComponents/DocumentLinkModal'
import Email from '../components/Email'
import Eyecon from 'pageComponents/_common/Eyecon'
import MyContext from 'setup/context'
import { useCheckout2 } from 'setup/CheckoutProviderFields'
import { CheckCircle } from '@mui/icons-material'
import AddressViewEdit from '../components/address/AddressViewEdit'
import AddressBox from '../components/address/AddressBox'
import { CUSTOMER_VISIBLE_AREAS } from 'pageComponents/_common/constants/customerVisibleNoteAreas'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NoteAndAreaModal from 'pageComponents/ShoppingCart/uiComponents/NoteAndAreaModal'
import { StatusIcon } from './Checkout2Summary'

const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`
const EditPriceIcon = styled.div`
	cursor: pointer;
	margin-right: 8px;
`
const Err = styled.span`
	color: red;
	font-size: 0.75em
`
const Ok = styled.span`
	color: black;
	font-size: 0.75em
`

export default function OtherInfo(props) {
	const { userInfo } = useContext(MyContext);
	const {
		checkoutEditableFields,
		setCheckoutEditableFields,
		setCheckoutEditableField,
		validationStatus,
		inPageRefs,
		openEditors,
		showBillingAddressEditor,
		setShowBillingAddressEditor,
		checkoutInitializeData
	} = useCheckout2();

	const [showAttach, setShowAttach] = useState(false);
	const [showPaperwork, setShowPaperwork] = useState(false);
	const [noteModal, setNoteModal] = useState(null);

	function updateNoteAreas(u, noteAreas) {
		setCheckoutEditableFields({ ...checkoutEditableFields, NoteAreas: noteAreas });
	}

	//Monitor the checkbox for Billing same as Shipping. If it is checked, then the address editor is not open
	useEffect(() => {
		if (checkoutEditableFields.IsBillingSameAsShipping) {
			setShowBillingAddressEditor(false);
		}
	}, [checkoutEditableFields]);

	const billingAddress = {
		Name: checkoutEditableFields.BillingAddress_Name,
		FirstName: checkoutEditableFields.BillingAddress_FirstName,
		LastName: checkoutEditableFields.BillingAddress_LastName,
		Address1: checkoutEditableFields.BillingAddress_Address1,
		Address2: checkoutEditableFields.BillingAddress_Address2,
		City: checkoutEditableFields.BillingAddress_City,
		State: checkoutEditableFields.BillingAddress_State,
		Zip: checkoutEditableFields.BillingAddress_Zip,
		Country: checkoutEditableFields.BillingAddress_Country,
		Phone: checkoutEditableFields.BillingAddress_Phone,
		PhoneExt: checkoutEditableFields.BillingAddress_PhoneExt,
	};
	const [currentEditingAddress, setCurrentEditingAddress] = useState(billingAddress);

	const shippingAddress = {
		Name: checkoutEditableFields.ShippingChoices_Name,
		FirstName: checkoutEditableFields.ShippingChoices_FirstName,
		LastName: checkoutEditableFields.ShippingChoices_LastName,
		Address1: checkoutEditableFields.ShippingChoices_Address1,
		Address2: checkoutEditableFields.ShippingChoices_Address2,
		City: checkoutEditableFields.ShippingChoices_City,
		State: checkoutEditableFields.ShippingChoices_State,
		Zip: checkoutEditableFields.ShippingChoices_Zip,
		Country: checkoutEditableFields.ShippingChoices_Country,
		Phone: checkoutEditableFields.ShippingChoices_Phone,
		PhoneExt: checkoutEditableFields.ShippingChoices_PhoneExt,
	};

	const billToValid = validationStatus.IsBillToValid && !openEditors.billing && !openEditors.billingAddress;

	return (
		<Area>
			<AreaHeading ref={inPageRefs.billingAndOtherSectionRef}>
				<h1>Billing &amp; Other</h1>
				<StatusIcon isSectionValid={billToValid} validText='Bill To section is valid' invalidText='Bill To section needs attention' sectionTitle='Bill to is Valid' /> 
			</AreaHeading>
			<LayoutArea>
				<AreaContentRow>
					<FlexChild>
						<label htmlFor='IsBillingSameAsShipping'>Billing address same as shipping</label>
						<StyledCheckbox
							id='IsBillingSameAsShipping'
							name='IsBillingSameAsShipping'
							type="checkbox"
							checked={checkoutEditableFields.IsBillingSameAsShipping}
							onChange={e => setCheckoutEditableField('IsBillingSameAsShipping', e.target.checked)}
						/>
					</FlexChild>
				</AreaContentRow>
				<AreaContentRow>
					{!checkoutEditableFields.IsBillingSameAsShipping &&
						<AddressViewEdit
							address={currentEditingAddress}
							addressUpdatedCallback={(addr) => setCurrentEditingAddress(addr)}
							addressType="billing"
							showEditor={showBillingAddressEditor}
							setShowEditor={setShowBillingAddressEditor}
						/>
					}
					{!!checkoutEditableFields.IsBillingSameAsShipping &&
						<AddressBox address={shippingAddress} />
					}
				</AreaContentRow>
				<AreaContentRow>
					<Bold>Order Notes</Bold>
				</AreaContentRow>
				<AreaContentRow>
					<FullWidth>
						<FlexInline>
							{userInfo?.isImpersonatorUser && (
								<IconContainer>
									<Eyecon {...{
										slash: checkoutEditableFields.NoteAreas?.length > 0 &&
											!CUSTOMER_VISIBLE_AREAS.some(a => checkoutEditableFields.NoteAreas.includes(a)),
										userInfo
									}} />
									<EditPriceIcon onClick={() => setNoteModal({ targetAreas: checkoutEditableFields.NoteAreas, type: 'order' })}>
										<FontAwesomeIcon icon="pencil-alt" color='#328EFC' />
									</EditPriceIcon>
								</IconContainer>
							)}
							<FullWidth>
								<Textarea
									id={`Notes`}
									placeholder="(optional)"
									value={checkoutEditableFields.Notes}
									rows={3}
									maxLength={512}
									onChange={(e) => setCheckoutEditableFields({ ...checkoutEditableFields, Notes: e.target.value })}
								/>
							</FullWidth>

							<NoteAndAreaModal
								saveCallback={updateNoteAreas}
								values={noteModal}
								isOpen={noteModal !== null}
								hide={() => setNoteModal(null)}
								allowedNoteAreas={checkoutInitializeData.ValidOrderNoteAreas}
								type='order'
							/>
						</FlexInline>
						<FullWidth style={{ "textAlign": "right", "width": "90%" }}>
							{checkoutEditableFields.Notes && (checkoutEditableFields.Notes?.length > 100) && (checkoutEditableFields.Notes?.length > 512 ? <Err>{`${checkoutEditableFields.Notes?.length} / 512`}</Err> : <Ok>{`${checkoutEditableFields.Notes?.length} / 512`}</Ok>)}
						</FullWidth>
					</FullWidth>
				</AreaContentRow>
				{userInfo?.isImpersonatorUser && (<>
					<AreaContentRow>
						<Bold>Confirmation Email</Bold>
					</AreaContentRow>
					<AreaContentRow>
						<Email />
					</AreaContentRow></>
				)}

				{userInfo && (<>
					<AreaContentRow>
						<Bold>Attachments &amp; Documentation</Bold>
					</AreaContentRow>
					<AreaContentRow>
						{!showPaperwork && (
							<HorizontalPack>
								<EyeSpace />
								<ButtonBlack id="AttachFilesBtn" onClick={() => setShowAttach(true)}>Attach Files</ButtonBlack>
							</HorizontalPack>
						)}
					</AreaContentRow>
					{showAttach && (
						<DocumentLinkModal
							{...{
								open: showAttach,
								hide: () => setShowAttach(false),
								accept: 'image/jpeg, application/pdf, image/png',
								allowedMessage: 'Maximum 5 files, 10MB size. Only jpg, png or pdf allowed.',
								maxFiles: 5,
							}}
						/>
					)}
				</>)}
			</LayoutArea>
		</Area>
	)
}
